
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【中古車】GT-Rを選ぶ際はここに注目！後悔しないGT-Rの選び方のポイントを解説
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            GT-Rの中古車を選ぶ際は、グレードや型式、価格相場などをチェックすることが大切です。グレードごとの特徴や中古車市場での価格相場を理解し、自分にとって最適なGT-Rを選んでください。スカイラインGT-Rシリーズの中古車をお探しでしたら、ぜひcar shop MIXにご相談ください。
            <div class="section-title">
              <span >低走行以外にも注目！中古車でGT-Rを購入時にチェックしたいポイント</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >中古車市場でも非常に人気のあるGT-Rですが、選ぶ際にはどのような点に注意すべきなのでしょうか。</p>
                    <p >こちらでは、中古車でGT-Rを購入する際にチェックしたいポイントを解説します。</p>
                    <h3 class="heading-number" data-number="◆">GT-Rのグレードごとの特徴</h3>  
                    <p >現行GT-Rには、「Pure edition」「Premium edition」「Black edition」「Track edition engineered by NISMO」の4つのグレードがあります。</p>
                    <p >「Pure edition」は、現行GT-Rのベースとなるグレードであり、現行GT-Rの中では最もベーシックなモデルです。タイヤ周辺やメカニズムの部分は上位グレードのモデルと比べても遜色なく、「これぞGT-R」という走行性を発揮してくれます。</p>
                    <p >「Premium edition」は、「ファッショナブルインテリア」というオプションを選択可能です。さらなる高級感を演出できるカラーラインナップが取りそろえられており、自分らしさを追求できます。<br>
                      ※ボディカラーは全シリーズ共通の6色展開です。</p>
                    <p >また、Premium edition以上のグレードには、軽量化と美しさを両立したFUJITSUBO製の「チタン合金製のマフラー」が採用されています。</p>
                    <p >「Black edition」とほかのグレードの最も大きな違いは、ルーフトリムとサンバイザーの色です。通常はグレーであるそれらの色が黒で統一されており、クールでスタイリッシュな印象に仕上がります。ボディカラーは黒以外も選べますので、黒一色だけではなく、好みの配色をチョイスするのもおすすめです。</p>
                    <p >「Track edition engineered by NISMO」は、日産自動車のモータースポーツ事業を手がける「NISMO（ニスモ）」のテクノロジーを結集させたグレードです。ボディの剛性を強化する「ボンディングボディ」や専用拡幅フロントフェンダーなど、走行性をとことん追求しています。</p>
                    <p >なお、NISMOが手がけるGT-Rとしては、現行GT-Rのトップモデルである「GT-R NISMO」があります。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-gtr-amg/used-car-gt-r-point.jpg" alt="Image">
                  </div>
              </div>
              <h3 class="heading-number" data-number="◆">燃費や年間維持費</h3>  
              <p >現行GT-Rの燃費は、カタログではどのグレードも一様に「7.8km／L」と記載されています。車の燃費は急発進・急加速や荷物の過積載などの運転環境によっても異なるため、カタログスペックどおりとは限りませんが、少なくとも性能上はグレードによる差はあまりないでしょう。</p>
              <p >また、自動車税は車の排気量によって変動しますが、現行GT-Rは排気量も同一なので、年間維持費にも差は生じません。</p>
              <p >ただし、旧モデルの中古車を購入する場合は話が変わってきます。例えば、GT-Rの2011年発売モデルは、燃費のカタログスペックは「8.7km／L」です。このように燃費や年間維持費は型式によって異なりますので、気になる型式のカタログスペックを比較してください。</p>
              <h3 class="heading-number" data-number="◆">型式</h3>  
              <p >GT-Rには現行のR35をはじめ、価格が高騰しているR34やR32など様々な型式があります。</p>
              <p >それぞれ異なる魅力があるため、型式ごとの特徴やストーリーを把握すると、車選びをより一層楽しめるでしょう。</p>
              <p >なお、現行のR35には3ペダルMTが採用されていないため、MT車を楽しみたい方には「スカイライン」の名を冠していたころの型式がおすすめです。</p>
              <h3 class="heading-number" data-number="◆">中古車価格相場</h3>
              <p >GT-Rのグレード別の中古車価格は、走行距離や年代などによって異なります。</p>
              <p >相場は、以下のとおりです。</p>
              <ul>
                <li>Pure edition：約600～1,100万円</li>
                <li>Premium edition：約400～1,300万円</li>
                <li>Black edition：約350～1,200万円</li>
                <li>Track edition engineered by NISMO：1,200～3,800万円</li>
              </ul>
              <p >GT-Rの中古車を購入する際は、この相場から大きくかけ離れない車を選ぶことをおすすめします。</p>
              <div class="c-btn">
                  <router-link to="/jigyo/oldCars" class="c-btn-anchor">中古車</router-link>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >GT-Rの中古車選びはcar shop MIXまでご相談ください！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >GT-Rを購入する際は「グレード」「燃費・年間維持費」「型式」「価格相場」をチェックするのがおすすめです。グレードは車の性能やオプションの有無などを左右し、上位になるほど料金も上がる傾向があります。</p>
                    <p >ただし、同じグレードでも型式が異なると性能に違いが生じる場合もあるため、注意が必要です。GT-Rに求めることを整理し、自分にとって最適なGT-Rを選んでください。</p>
                    <p >日産自動車のGT-Rには長い歴史があり、現在まで様々なグレード・型式の車種が登場しています。中古車を視野に入れると膨大なラインナップがあり、どのGT-Rを購入すべきか迷ってしまう方も多いかもしれません。</p>
                    <p >GT-Rの中古車選びに迷ったときは、car shop MIXにお任せください。一般市場ではなかなか手に入らない限定車や希少車を幅広くラインナップしております。</p>
                    <p >在庫がない場合も、オークションの代行などによりご希望の車をお探しすることが可能ですので、まずはお気軽にお問い合わせください。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="中古車のGT-Rニスモ" src="@/assets/img/column-gtr-amg/used-car-gt-r-nismo.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/contact" class="c-btn-anchor">お問い合わせ</router-link>
              </div>
            </div>
          </div>
          <column-footer :title="'GT-Rの中古車(MT・AT)選びにお悩みならcar shop MIXまでご相談ください！'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-gtr-amg/head-mv04.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}


</style>
